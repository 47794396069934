import React from "react";
import "./EssayForm.css";

class EssayForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "Please copy all text from content block and insert here",
      urls: [],
      data: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.makeRequest = this.makeRequest.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    var str = this.state.value;
    var pattern = /(?<={% assign pdpurl = ")([\s\S]*?)(?=" %})/g;
    var url_array = str.match(pattern);

    console.log(url_array);
    if (url_array) {
      var i;
      var resp_array = [];
      for (i = 0; i < url_array.length; i++) {
        url_array[i] = "https://www.foodspring." + url_array[i];
        resp_array[i] = {
          httpstatus: "checking URL",
          color: "#BE9471",
          description: "checking URL",
        };
      }

      this.setState({ urls: url_array });
      this.setState({ data: resp_array });
    } else {
      alert("Please insert copied content block code!");
    }

    event.preventDefault();
  }

  makeRequest(id, url) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open("HEAD", url, true);
      xhr.onreadystatechange = function () {
        resolve({
          id: id,
          url: url,
          status: xhr.status,
          statusText: xhr.statusText,
        });
        reject({
          id: id,
          url: url,
          status: xhr.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send(null);
    });
  }

  checkURL = () => {
    let data = this.state.data;
    for (var i = 0; i < this.state.urls.length; i++) {
      this.makeRequest(i, this.state.urls[i])
        .then((feedback) => {
          console.log(feedback);
          let statuscolor = "#999999";
          let statusdescription = "checking...";
          if (feedback.status === 200) {
            statusdescription = "Status code: 200 Success";
            statuscolor = "#95D63B";
          } else if (feedback.status < 400 && feedback.status >= 400) {
            statusdescription = "Status code: 30x Redirection";
            statuscolor = "#72CFD6";
          } else if (feedback.status === 400) {
            statusdescription = "Status code: 400 Request error: Bad Request";
            statuscolor = "#B82100";
          } else if (feedback.status === 401) {
            statusdescription = "Status code: 401 Request error: Unauthorized";
            statuscolor = "#B82100";
          } else if (feedback.status === 403) {
            statusdescription = "Status code: 403 Request error: Forbidden";
            statuscolor = "#B82100";
          } else if (feedback.status === 404) {
            statusdescription = "Status code: 404 Request error: Not Found";
            statuscolor = "#B82100";
          } else if (feedback.status >= 500) {
            statusdescription = "Status code: 50x Server error";
            statuscolor = "#B719AD";
          } else if (feedback.status < 100) {
            statusdescription = "Response: 0 No Status, Domain Error";
            statuscolor = "#B719AD";
          } else {
            statusdescription =
              "Status code: " + feedback.status + " Request error: Unknow";
            statuscolor = "#999999";
          }
          data[feedback.id] = {
            httpstatus: feedback.status,
            color: statuscolor,
            description: statusdescription,
          };
          this.setState({ data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    return (
      <div className="EssayForm">
        <header className="EssayForm-header">
          URL Checker for content blocks:
        </header>
        <div className="EssayForm-row">
          <form className="EssayForm-form" onSubmit={this.handleSubmit}>
            <label>Please paste code: </label>
            <textarea
              rows="20"
              cols="50"
              value={this.state.value}
              onChange={this.handleChange}
            />
            <input type="submit" value="Extract URLs" />
          </form>
          {this.state.urls.length > 0 && (
            <div className="EssayResults">
              <p>URLs checked:</p>
              <p>
                {this.state.urls.map((url, index) => (
                  <li key={index}>
                    {url}&nbsp;...&nbsp;
                    <span style={{ color: this.state.data[index].color }}>
                      {this.state.data[index].description}
                    </span>
                  </li>
                ))}
              </p>
              <button onClick={this.checkURL}>Check URLs</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EssayForm;
